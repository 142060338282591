.contentWrapper{
  margin-top: 150px;
  width: 100%;
  background: var(--main-bg-color);
  color: var(--primary-text-color);
}

.footerData{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding: 2rem 0;
}

.footerData li{
  list-style-type: none;
}

.footerData ul{
  margin: 0;
}

@media only screen and (max-width: 950px) {
  .footerData{
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }
}

.footerCopyright{
  height: 60px;
  background: var(--primary-color);
  width: 89%;
}

.footerSocial{
  background: var(--main-bg-color);
}

.copyrightContainer {
  display: flex;
}

.footerCopyright {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.textCopyright {
  color: #222629;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.25rem;
}

.footerSocial > a {
  padding: 0.15rem;
}

.footerSocial > a:hover {
  transform: scale(1.25);
}
