.headingChildren {
    margin-left: auto;
    align-self: center;
}

.headingChildren>div {
    display: flex;
    gap: 1rem;
}

.inputSearch,
.inputSearch>input,
.inputSearch>input::placeholder {
    border-color: var(--secondary-color);
    background-color: white;
    color: var(--secondary-color);
}

.selectArrowIcon {
    color: var(--primary-color) !important;
}

.classGroupActions {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    padding: 0.5rem;
    gap: 0.5rem;
}

.halfBackground {
    position: absolute;
    background: var(--main-bg-color);
    width: 100%;
    z-index: -1;
    height: 300px;
}

.listWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: auto;
}

.mainContent {
    background-color: var(--secondary-bg-color);
    padding: 1rem;
    margin: 2rem auto;
    width: 80%;
    border-radius: 0.5rem;
}

@media only screen and (max-width: 950px) {
    .mainContent {
        padding: 0.5rem;
        width: 98%;
    }
}

.listPagination {
    text-align: right;
    position: relative;
    top: 50px;
}