.stepListBackground {
  background-color: var(--main-bg-color);
  height: 7rem;
}
.stepList {
  background-color: #575A5C;
  border-radius: 8px;
  padding: 2.5rem;
  margin: 0 4rem;
  position: relative;
  top: -6rem;
}

.heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.heading > h4 {
    color: var(--primary-text-color);
    font-size: 2.1rem;
    font-weight: 400;
}

.heading > button {
  color: var(--primary-text-color);
  background: var(--primary-color);
  border: none;
  width: 160px;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.heading > button:hover{
  filter:brightness(1.1)
}

@media only screen and (max-width: 950px) {
  .stepList {
    background-color: #575A5C;
    border-radius: 8px;
    padding: 1rem;
    margin: 0 1rem;
  }

  .heading > h4 {
    color: var(--primary-text-color);
    font-size: 2rem;
    font-weight: 400;
  }
}
