.contentWrapper {
}

.headingChildren {
    margin-left: auto;
    align-self: center;
}

.headingChildren > div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.halfBackground {
    position: absolute;
    background: var(--main-bg-color);
    width: 100%;
    z-index: -1;
    height: 300px;
}

.listWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: auto;
}

.mainContent {
    margin: 2rem 4rem;
}

@media only screen and (max-width: 950px) {
    .mainContent {
        margin: 2rem 0.5rem;
    }   
}

