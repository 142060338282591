.profileWrapper {
  display: flex;
  margin: 2rem 0;
  padding: 0 3rem;
  flex-direction: column;
  gap: 1.5rem;
}

.profileWrapper .studentData {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.profileWrapper .studentData > div {
  display: flex;
  gap: 1rem;
}

.profileWrapper .profileImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  height: 100px;
  width: 100px;
  border-radius: calc(100px / 2);
}

.profileWrapper .profileImg > img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: calc(100px / 2);
}

.profileWrapper .name,
.profileWrapper .displayInfo {
  display: flex;
  flex-direction: column;
}

.profileWrapper .mentorshipInfo {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.primaryText {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-color);
}

@media only screen and (max-width: 950px) {
  .profileWrapper {
    padding: 0 1rem;
  }
}