.mainDiv {
  display: flex;
  justify-content: center;
  gap: 5rem;
  background-color: var(--main-bg-color);
  height: calc(100vh - 169px);
}

.login input {
  height: 45px;
}

.image img {
  height: calc(100vh - 169px);
}

.image {
  display: none;
}

@media only screen and (min-width: 950px) {
  .image {
    display: block;
  }
}

.login {
  padding-top: 48px;
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.login .loginTitle {
  font-size: 60px;
  font-weight: 200;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: var(--primary-text-color);
  margin-left: 20px;
}

.loginForm {
  width: 100%;
  height: 220px;
  background-color: var(--login-bg-color);
  border-radius: 8px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  padding: 24px;
}

button.loginBtn,
button.signUpBtn {
  width: 48%;
}

button.loginBtn:hover,
button.signUpBtn:hover {
  opacity: 0.75;
}

a.loginForgotPassword {
  color: #3b88c3;
  text-decoration: underline;
  margin: 8px auto 0 auto;
  font-size: 1rem;
}
