.contentWrapper {
  --height: calc(75px);

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--height);
  background: var(--main-bg-color);
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 1.5rem 0;

  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
}

.subjectData {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 0.5rem;
}

.subjectData > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 136px;
}

.subjectName {
  font-size: 1rem;
  font-weight: 600;
}

.subjectCode {
  color: #8a8a8e;
  font-size: 0.9rem;
  font-weight: 500;
}

.rankNumber {
  background-color: var(--primary-color);
  height: var(--height);
  padding: 0 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem 0 0 0.5rem;
}

@media only screen and (max-width: 950px) {
  .contentWrapper {
    --height: calc(115px);
  }

  .subjectName {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .subjectCode {
    color: #8a8a8e;
    font-size: 0.6rem;
    font-weight: 500;
  }
  
  .rankNumber {
    font-size: 1.6rem;
    padding: 0 0.3rem;
  }

  .subjectData > div {
    gap: 0.5rem;
  }
}
