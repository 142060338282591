header.appHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header.appHeader > a img {
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  height: 65px;
}

.appLogo {
  margin-left: 16px;
}

.mainMenu {
  display: flex;
}

.mainMenu > button {
  border: none;
  color: var(--primary-text-color);
  width: 100px;
  height: calc(65px + 8px);
  cursor: pointer;
}

.mainMenu > button:hover {
  filter: brightness(1.1);
}

.mainMenu > .profileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-color);
}

.mainMenu > .logoutButton {
  background: var(--primary-color);
}

.appHeader .headerNav {
  display: flex;
  justify-content: right;
  align-items: center;
}

.appHeader .navBar {
  margin: 0 0.5rem 0 2rem;
  display: flex;
  gap: 2rem;
}

.navBar a {
  font-weight: 500;
  font-size: 14px;
  line-height: 1rem;
  color: var(--primary-text-color);
  opacity: 0.87;
  letter-spacing: 1.25px;
}

.navBar .current {
  color: var(--primary-color);
}

.navBar a:hover {
  color: var(--primary-color);
}

.headerNav > .mobileNavBarButton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  font-size: 1.4rem;
  padding: 0.6rem;
  background: transparent;
  height: 2.4rem;
}

.mobileNavBar > .menuItemMobile{
  padding: 0.8rem 3rem 0.8rem 1.5rem;
  font-size: 1rem;
}

.mobileNavBar{
}

@media only screen and (max-width: 950px) {
  .appHeader .navBar{
    display: none;
  }
}

@media only screen and (min-width: 950px) {
  .headerNav > .mobileNavBarButton{
    display: none;
  }
}

.languageList{
  width: 125px;
}

.languageList > span{
  display: flex;
  gap: 0.25rem;
}

.languageList svg{
  border-radius: 0.25rem;
}