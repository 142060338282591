
h1{
  font-size: 4rem;
  font-weight: 300;
  padding: 1.5rem 0;
  margin: 0;
}

div.app-header{
  padding-top: 1rem;
  background: var(--main-bg-color);
}

.cyta-dropdown{
  background: var(--main-bg-color);
  border-color: var(--primary-color);
  color: var(--primary-color)
}