.chartStatsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
  background-color: var(--main-bg-color);
  padding: 3rem 0;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.15px;
  color: var(--primary-text-color);
}

.statsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 4rem;
  padding-right: 1rem;
}

.fromToDropdownContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
}

.selectArrowIcon {
  color: var(--primary-color)!important;
}

.dropdownFlex {
  display: flex;
  flex-direction: column;
}

.dropdownFlex > span {
  color: var(--primary-text-color);
  font-weight: 400;
}

.statsCardContainer {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  flex-wrap: wrap;
}

.statsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
}

.stat {
  font-size: 9rem;
  line-height: 8rem;
  font-weight: 700;
  letter-spacing: -1.5px;
  color: var(--primary-color);
}

.statBlue {
  color: #006693;
}

.studentsComplaintsStatsContainer {
  display: flex;
  gap: 8px;
}

.studentsComplaintsStats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.studentsComplaintsStat {
  color: var(--primary-text-color);
  font-size: 16px;
  font-weight: 500;
}