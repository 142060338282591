.heading {
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 7px 30px;
  gap: 40px;
  margin-bottom: 1.5rem;
  background: var(--secondary-bg-color);
  cursor: pointer;
}

.heading:hover {
  filter:brightness(1.1)
}

.heading > div {
    font-size: 2.1rem;
    font-weight: 400;
}

.stepDetailWrapper > div:first-child {
  display: flex;
  justify-content: flex-end;
} 

.stepDetailWrapper button:hover{
  filter:brightness(1.1)
}

.stepDetail{
  display: flex;
  align-items: stretch;
  color: var(--primary-text-color);
}

.stepDetail > div {
  padding: 0.5rem;
  min-width: 50%;
}

.stepDetail h4{
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary-text-color);
}

.stepDetail ul{
  list-style: none;
}

.openedStep{
  visibility: visible;
  display: block;
}

.closedStep{
  visibility: hidden;
  display: none;
}

@media only screen and (max-width: 950px) {
  .heading {
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    background: var(--secondary-bg-color);
    cursor: pointer;
  }
  
  .heading > div {
    font-size: 1rem;
  }

  .stepDetail{
    flex-direction: column;
  }

  .stepDetail > div {
    padding: 0.5rem;
    min-width: 100%;
  }
  
  .stepDetail h4{
    font-size: 2rem;
    font-weight: 400;
    color: var(--primary-text-color);
  }
}