.heading {
  background: var(--main-bg-color);
  color: var(--primary-text-color);
}

.heading > .title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 4rem;
  border-bottom: var(--primary-color) solid 4px;
  padding-bottom: 0.5rem;
}

.heading > .title > h1 {
  /* white-space: nowrap; */
  text-transform: capitalize;
  font-size: 4rem;
}

@media only screen and (max-width: 950px) {
  .heading > .title > h1 {
    font-size: 2.5rem;
  }

  .heading > .title {
    margin: 0 1.5rem;
  }
}

.description {
  padding: 0.1rem 4rem;
}

.description > h5 {
  padding: 0.6rem 0;
  font-size: 1.5rem;
  color: var(--primary-text-color);
}

.description > p {
  font-size: 1rem;
}
