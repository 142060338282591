.contentWrapper {
  color: var(--primary-text-color);
  position: relative;
  margin-top: 1rem;
}

.topContent {
  display: flex;
  justify-content: space-between;
  height: 74px;
  background: var(--main-bg-color);
  padding: 1rem 1rem;
}

.topContent > div {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
}

.previewTitle {
  font-size: 1.5rem;
}

.halfBackgroud {
  position: absolute;
  background: var(--main-bg-color);
  width: 100%;
  z-index: -1;
  height: 500px;
  display: none;
}

.cardGroupWrapper {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 0 .5rem;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 2330px) {
  .cardGroupWrapper {
    max-width: 1700px;
    margin: auto;
  }
}
