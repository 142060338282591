.contentWrapper {
  color: var(--primary-text-color);
}

.stepListBackground {
  background-color: var(--main-bg-color);
  height: 7rem;
}

.stepList {
  background-color: #575a5c;
  border-radius: 8px;
  padding: 2.5rem;
  position: relative;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.heading > h4 {
  color: var(--primary-text-color);
  font-size: 2.1rem;
  font-weight: 400;
}
