@import "~antd/dist/antd.css";

:root {
  --primary-color: #5E8FFC;
  --secondary-color: #192A75;

  --main-bg-color: #222629;
  --secondary-bg-color: #535557;
  --login-bg-color: #e0e6eb;
  --primary-text-color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-text-color);
}

#root {
  position: relative;
}

.ant-btn {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--primary-text-color);
}

.ant-btn.ant-btn-background-ghost {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-link {
  color: var(--primary-color);
  border-color: transparent;
  background-color: transparent;
}

.ant-select-selector {
  color: var(--primary-color) !important;
  background-color: transparent !important;
  border-color: var(--primary-color) !important;
}
