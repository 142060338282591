.contentWrapper{
}

.previewAreaStudentCardGroup{
  background: var(--secondary-bg-color);
  padding: 1rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
  gap: 1rem;
}

.link{
  text-align: right;
  font-weight: 500;
  font-size: 14px;
}

.previewTitle {
  font-size: 1.5rem;
}

.topContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--main-bg-color);
  border-radius: 0.5rem;
  opacity: 0.9;
}