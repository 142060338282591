.contentWrapper {
    margin-bottom: 1rem;
}

.tableArea {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0 4rem;
}

.tableArea > div {
    width: 50%;
}

.talentCoinArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
}

.contentWrapper h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
}

.subjectArea {
    display: flex;
    align-items: center;
    height: 3.5rem;
}

.imageFilter {
    filter: invert(53%) sepia(93%) saturate(2878%) hue-rotate(359deg) brightness(100%) contrast(108%);
}

@media only screen and (max-width: 950px) {
    .tableArea {
        flex-wrap: wrap;
        margin: 0 1rem;
    }
    
    .tableArea > div {
        width: 100%;
    }   
}