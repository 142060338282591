.contentWrapper{
    color: var(--primary-text-color);
}

.headingChildren{
    text-align: right;
}

.headingChildren > div{
    display: inline-block;
    text-align: left;   
}

.headingChildren h2{
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0.5rem 0;
}

.headingChildren h3{
    font-size: 3rem;
    font-weight: 400;
    margin: 0;
}

.spin{
    background-color: var(--main-bg-color);
}