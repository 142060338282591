.contentWrapper {
}

.tableArea {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 2rem;
    margin: 0 3rem;
}

.tableArea > div {
    width: 50%;
}

.talentCoinArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.5rem 0;
}

.contentWrapper h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
}

.subjectArea {
    display: flex;
    align-items: center;
    height: 3.5rem;
}
 @media only screen and (max-width: 950px) {
    .tableArea {
        flex-wrap: wrap;
        margin: 0 1rem;
    }

    .tableArea > div {
        width: 100%;
    }
 }