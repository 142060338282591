.appHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navGroup{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header.appHeader > img {
  margin-left: 16px;
  margin-bottom: 8px;
  height: 65px;
}

.appHeader .navGroup .navBar {
  margin-right: auto;
  margin-left: 100px;
}

.appLogo {
  margin-left: 16px;
}

.navBar a {
  margin-right: 65px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-text-color);
  opacity: 0.87;
}

.navBar .current {
  color: var(--primary-color);
}

.navBar a:hover {
  color: var(--primary-color);
}

.socialMedia {
  background-color: var(--primary-color);
  width: 32px;
  height: calc(65px + 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  
}

.socialMedia > a {
  padding: 2px;
  transform: scale(1.10);
}

.socialMedia > a:hover {
  transform: scale(1.25);
}

.languageList {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  width: 1.5rem;
  margin: 0 0.5rem;
  padding-top: 4px;
}

.languageList svg {
  cursor: pointer;
  border-radius: 0.25rem;
}

.appHeader > .navGroup >  .mobileNavBarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  font-size: 1.4rem;
  padding: 0.6rem;
  background: transparent;
  height: 2.4rem;
}

.mobileNavBar > .menuItemMobile {
  padding: 0.8rem 3rem 0.8rem 1.5rem;
  font-size: 1rem;
}

@media only screen and (max-width: 950px) {
  .appHeader .navBar {
    display: none;
  }

}

@media only screen and (min-width: 950px) {
  .appHeader > .navGroup > .mobileNavBarButton {
    display: none;
  }

  .socialMedia {
    width: 200px;
    flex-direction: row;
  }

  .socialMedia > a {
    padding: 12px;
    transform: scale(1.60);
  }

  .socialMedia > a:hover {
    transform: scale(1.85);
  }

}

.languageSocialMediaArea {
  display: flex;
  flex-direction: row;
}
