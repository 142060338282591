.contentWrapper > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.primaryText {
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-color);
  }