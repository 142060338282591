.contentWrapper{
  --height: 140px;
  --avatarDiameter: 85px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--height);
  background: var(--main-bg-color);
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 1.5rem 0;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  color: var(--primary-text-color);
}

.studentData {
  display: flex;
  align-items: center;
}

.studentData > .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  height: var(--avatarDiameter);
  width: var(--avatarDiameter);
  border-radius: calc(var(--avatarDiameter) / 2);
  margin: 0 1rem;
}

.studentData > div > img {
  object-fit: cover;
  height: calc(var(--avatarDiameter) - 2px);
  width: calc(var(--avatarDiameter) - 2px);
  border-radius: calc(var(--avatarDiameter) / 2);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0rem 0 0 0.5rem;
}

.rankNumber {
  background-color: var(--primary-color);
  height: var(--height);
  padding: 0 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem 0 0 0.5rem;
}


.studentName {
  font-size: 1rem;
  font-weight: 600;
}

.studentEmail {
  word-break: break-all;
  color: #8a8a8e;
  font-size: 0.8rem;
  font-weight: 500;
}

.studentGradeCountry {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.studentGrade {
  font-size: 1rem;
  font-weight: 600;
}

.studentCountry {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  gap: 0.25rem;
}

@media only screen and (max-width: 950px) {
  .contentWrapper {
    --height: 200px;
    --avatarDiameter: 75px;
    
    max-width: 430px;
    flex-wrap: wrap;
    padding: 1.5rem 0.5rem 1.5rem 0;
  }

  .buttonGroup {
    flex-direction: row;
    margin: 1rem 0 0 1rem;
  }

  .rankNumber {
    font-size: 1.6rem;
    padding: 0 0.3rem;
    border-radius: 0 0.5rem 0.5rem 0;
    height: var(--avatarDiameter);
  } 

  .studentEmail {
    font-size: 0.7rem;
  }

  .studentData > .avatar {
    margin: 0 0.5rem
  }
}
