.profileWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px 0;
  padding: 0 1rem;
}

.profileWrapper .profileImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  height: 174px;
  width: 174px;
  border-radius: calc(174px / 2);
  margin: 0 1rem;
}

.profileWrapper .profileImg > img {
  height: 170px;
  width: 170px;
  object-fit: cover;
  border-radius: calc(170px / 2);
}

.profileWrapper .profilePersonalInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profileWrapper .class {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profileWrapper .class > div {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.profileWrapper .profileBtns {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-content: center;
  gap: 3rem;
}

.profilePersonalInfo .email {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  
}

.profilePersonalInfo .nameWrapper {
  display: flex;
  gap: 2rem;
}

.nameWrapper .name {
  display: flex;
  flex-direction: column;
}

.nameWrapper .name > :first-child,
.profilePersonalInfo .email > :first-child,
.classTitle {
  font-weight: 500;
  font-size: 1rem;
}

.profilePersonalInfo .email > .primaryText{
  word-break: break-all;
}

.primaryText {
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary-color);
}

@media only screen and (max-width: 950px) {
  .primaryText {
    font-size: 1.2rem;
  }
  
}

.classSelect {
  min-width: 180px;
}

.selectArrowIcon {
  color: var(--primary-color);
}
