.contentWrapper {
    margin: 2rem 3rem;
}

.contentWrapper h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
}

.contentWrapper > .ant-tabs-tab {
    color: white;
}

.mentorshipArea {
    display: flex;
    align-items: center;
    height: 3.5rem;
}

@media only screen and (max-width: 950px) {
    .contentWrapper {
        margin: 2rem 1rem;
    }
}