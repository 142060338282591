.attachmentTitle {
    color: #000000d9
}

div.formAttachment{
    display: flex;
    flex-wrap: wrap;
}

div.formAttachment div:has(.attachmentTypeSelect),
div.formAttachment div:has(input[type="text"]),
div.formAttachment div:has(button)
{
    width: 24%;
}

.attachmentTypeSelect, .addButton{
    width: 100%;
}

@media only screen and (max-width:950px) {
    div.formAttachment div:has(.attachmentTypeSelect),
    div.formAttachment div:has(input[type="text"]),
    div.formAttachment div:has(button)
    {
        width: 100%;
    }

    div.formAttachment div:has(input){
        width: 100%;
    }
}
