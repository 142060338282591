.contentWrapper {
  color: var(--primary-text-color);
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text {
  color: var(--primary-text-color);
}

.contentWrapper .box {
  padding: 0 4rem;
}

.box h2 {
  margin-top: 1rem;
}

.reviewSection {
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  gap: 3rem;
}

.goalsSection {
  margin: 1.5rem 0;
}

.goalsGroup {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.sessionGroup {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.openModalButton {
  margin: 2rem 0;
}

.openModalButton span{
  white-space: normal;
}

.openModalButton button{
  height: fit-content;
}

@media only screen and (max-width: 950px) {
  .contentWrapper .box {
    padding: 0 1rem;
  } 
}